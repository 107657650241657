<template>
  <div class="">
    <AdminTitle title="Account Settings" />

    <v-card max-width="1000" class="box-shadow" outlined>

      <v-card-text class="pa-7">
        <v-row>
          <v-col cols="3" class="text-center">
            <template v-if="user">
              <UserPhoto
                :id="user.userid"
                :size="150"
                photoSize="medium"
                editable
                rounded
              />
            </template>
          </v-col>
          <v-col cols="9">
            <v-alert type="error" border="left" class="mb-6" v-if="status.error">{{ status.error }}</v-alert>

            <preloader v-if="!user" />
            <v-form ref="accountForm" v-if="user">
              <v-text-field
                v-model="user.fullName"
                :rules="[rules.required]"
                label="Full Name"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="user.email"
                :rules="[rules.required, rules.email]"
                @click="confirmEmail()"
                persistent-hint
                label="Email"
                outlined
              ></v-text-field>
              
              <!-- <v-text-field
                v-model="user.email"
                :rules="[rules.required, rules.email]"
                label="Email"
                :append-icon="getSignupMethod() !== 'Email and Password' ? 'mdi-lock' : ''"
                :hint="getSignupMethod() !== 'Email and Password' ? 'Email cannot be updated if your account was created using Google or Facebook.' : ''"
                :readonly="getSignupMethod() !== 'Email and Password' || !status.accountConfirmed"
                @click="confirmEmail()"
                persistent-hint
                outlined
              ></v-text-field> -->

              <v-text-field
                label="Signup Method"
                :value="getSignupMethod()"
                append-icon="mdi-lock"
                outlined
                readonly
              ></v-text-field>


              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    label="Account Created"
                    :value="currentUser.metadata.creationTime"
                    append-icon="mdi-lock"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    label="Last Login"
                    :value="currentUser.metadata.lastSignInTime"
                    append-icon="mdi-lock"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-btn
                @click="validateProfile()"
                :loading="status.updating"
                color="primary"
                small
              >Update</v-btn>
            </v-form>

            <div class="mt-10 mb-7">
              <span class="font-weight-bold subtitle-1 secondary--text">Password Setting</span>
            </div>

            <!-- <v-alert type="info" border="left" v-if="getSignupMethod() !== 'Email and Password'">
              Password cannot be updated if your account was created using Google or Facebook.
            </v-alert> -->

            <v-alert v-if="status.passwordError" type="error" class="mb-7" border="left">{{ status.passwordError }}</v-alert>

            <v-form ref="passwordForm" @submit.prevent="validatePassword()">
              <div class="mb-5">Forgot your current password? <button @click="sendResetEmail()" type="button" class="primary--text">{{ sendingResetEmail ? 'Sending...' : 'Send reset email.' }}</button></div>
              
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="password.current"
                    :rules="[rules.required]"
                    label="Current Password"
                    type="password"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="password.new"
                    :rules="[rules.required, rules.password]"
                    label="New Password"
                    type="password"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    :rules="[rules.required, (value) => value == password.new || 'Password did not matched.']"
                    label="Confirm Password"
                    type="password"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>


              <div class="d-flex gap-5">
                <v-btn
                  @click="validatePassword()"
                  :loading="status.validatingPassword"
                  color="primary"
                  small
                >Update Password</v-btn>

                <v-btn
                  @click="clearPasswordForm()"
                  :disabled="status.validatingPassword"
                  text
                  small
                >Clear</v-btn>
              </div>

            </v-form>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ConfirmPassword />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import firebase from 'firebase'
import rules from '@/rules'

export default {
  name: 'AccountSettings',

  metaInfo: {
    title: 'Account Settings'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      sendingResetEmail: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.user.status,
      password: state => state.user.password,
    }),

    currentUser: function () {
      if (firebase.auth().currentUser) {
        return firebase.auth().currentUser
      }
      else {
        return {}
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'updateProfile',
      'updatePassword',
    ]),

    getSignupMethod() {
      if (this.currentUser.providerData[0].providerId == 'google.com') return 'Google'
      else if (this.currentUser.providerData[0].providerId == 'facebook.com') return 'Facebook'
      else return 'Email and Password'
    },

    validateProfile() {
      if (this.$refs.accountForm.validate()) {
        this.updateProfile()
      }
    },

    confirmed() {
      this.confirmedPassword = true
    },

    confirmEmail() {
      this.$store.commit('user/setShowConfirmPassword', true)
    },

    validatePassword() {
      if (this.$refs.passwordForm.validate()) {
        Promise.all([this.updatePassword()])
        .then(() => {
          this.$refs.passwordForm.reset()
        })
      }
    },

    clearPasswordForm() {
      this.$refs.passwordForm.reset()
    },

    sendResetEmail() {
      this.sendingResetEmail = true
      
      firebase.auth()
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
          this.$store.dispatch('showSuccess', 'Email sent')
        })
        .catch((error) => {
          console.log(error.message)
          this.$store.dispatch('showSuccess', error.message)
        })
        .finally(() => {
          this.sendingResetEmail = false
        })
    }
  },

}

</script>
